import React, {useEffect, useState} from 'react';

const {useLocation} = require("react-router-dom");

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

const QSP = () => {
    const location = useLocation();
    const query = useQuery();
    const [list, setList] = useState([])
    const [valid, setValid] = useState(false);

    const desiredPath = "/patient/search";

    useEffect(() => {
        let l = []
        for (const [key, value] of query.entries()) {
            l.push({key, value})
        }

        if (query.get("source") !== "elixir") return setValid(false)
        if (!query.get("elixirPatientProfileId")) return setValid(false)
        if (!query.get("elixirDatasetId")) return setValid(false)
        if (location.pathname !== desiredPath) return setValid(false)

        // if (!query.get("passportNumber") && !query.get("idNumber"))
        //     return setValid(false)

        setValid(true)

        setList(l)
    }, [])


    return (
        <div className={"content"}>
            <p className={"heading"}>A2D24 Test Space</p>
            <br/>
            <div className={"item"}>
                <div style={{float: "left"}}>The request must be made to the path "{desiredPath}"</div>
                <div style={{float: "left", color: location.pathname === desiredPath ? "#97f797" : "#de7777"}}>
                    {location.pathname === desiredPath ? "Correct path" : "Invalid path"}
                </div>
            </div>
            <div style={{display: "flex", flexDirection: "column", alignItems: "flex-start"}}>
                <p className={"heading"}>We Require 3 parameters:</p>

                <div className={"item"}>
                    <div style={{float: "left"}}>passportNumber</div>
                    <div style={{float: "left", color: query.get("passportNumber") ? "#97f797" : "#de7777"}}>
                        {query.get("passportNumber") ? query.get("passportNumber") : "Not Provided"}
                    </div>
                </div>

                <div className={"item"}>
                    <div style={{float: "left"}}>SA ID Number</div>
                    <div style={{float: "left", color: query.get("idNumber") ? "#97f797" : "#de7777"}}>
                        {query.get("idNumber") ? query.get("idNumber") : "Not Provided"}
                    </div>
                </div>

                <div className={"item"}>
                    <div style={{float: "left"}}>Elixir Patient Profile ID - **REQUIRED!</div>
                    <div style={{float: "left", color: query.get("elixirPatientProfileId") ? "#97f797" : "#de7777"}}>
                        {query.get("elixirPatientProfileId") ? query.get("elixirPatientProfileId") : "Not Provided"}
                    </div>
                </div>


                <div className={"item"}>
                    <div style={{float: "left"}}>Elixir Dataset ID - **REQUIRED!</div>
                    <div style={{float: "left", color: query.get("elixirDatasetId") ? "#97f797" : "#de7777"}}>
                        {query.get("elixirDatasetId") ? query.get("elixirDatasetId") : "Not Provided"}
                    </div>
                </div>

                <div className={"item"}>
                    <div style={{float: "left"}}>source - **REQUIRED! Must be 'elixir'</div>
                    <div style={{float: "left", color: query.get("source") === 'elixir' ? "#97f797" : "#de7777"}}>
                        {query.get("source") ? query.get("source") : "Not Provided"}
                    </div>
                </div>

                <br/>

            </div>
            <div style={{color: valid ? "#97f797" : "#de7777"}}>
                <strong
                    style={{color: valid ? "green" : "red"}}>Result:</strong> {valid ? "You have made a valid request" : "You have made an invalid request, please check the parameters above and try again"}
            </div>
            <br/>
            <div className={"heading"}>
                Examples:

            </div>
            <div>
                <a href={"https://elixirtestspace-dev.a2d24.dev/patient/search?&idNumber=&elixirPatientProfileId=543Af2738&source=elixir"}>Elixir
                    Patient Profile ID Only (Invalid)
                    Example</a>
            </div>
            <div>
                <a href={"https://elixirtestspace-dev.a2d24.dev/patient/search?elixirPatientProfileId=543Af2738&elixirDatasetId=x1234&source=elixir"}>Elixir
                    Patient Profile ID and Dataset ID Only
                    Example</a>
            </div>
            <div>
                <a href={"https://elixirtestspace-dev.a2d24.dev/patient/search?idNumber=8001010101087&passportNumber=A346543&elixirPatientProfileId=543Af2738&elixirDatasetId=x1234&source=elixir"}>All
                    fields
                    Example</a>
            </div>
            <div>
                <a href={"https://elixirtestspace-dev.a2d24.dev/patient/search?idNumber=&passportNumber=A346543&elixirPatientProfileId=543Af2738&elixirDatasetId=x1234&source=elixir"}>Null Parameter Example
                </a>
            </div>
            <div>
                <a href={"https://elixirtestspace-dev.a2d24.dev/patient/search?idNumber=&passportNumber=&elixirPatientProfileId=&source=elixir"}>No
                    identifier (Invalid) Example</a>
            </div>
            <div>
                <a href={"https://elixirtestspace-dev.a2d24.dev/patient?idNumber=&passportNumber=&elixirPatientProfileId=&source=elixir"}>Incorrect
                    Path (Invalid) Example</a>
            </div>
            <div>
                <a href={"https://elixirtestspace-dev.a2d24.dev/patient/search?idNumber=8001010101087&source=Elixir"}>Incorrect
                    Source (Invalid) Example</a>
            </div>

            <p className={"heading"}>You have provided the following parameters:</p>
            {list.map((item, index) => {
                return <p key={index}>{item.key} - {item.value}</p>
            })}
        </div>
    );
};

export default QSP;
