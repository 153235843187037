import './App.css';
import {BrowserRouter as Router, Route, Switch} from "react-router-dom";
import QSP from "./QSP";

function App() {
  return (
    <div className="App">
       <Router>
            <Switch>
                <Route component={QSP}/>
            </Switch>
       </Router>
    </div>
  );
}

export default App;
